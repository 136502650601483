<template>
  <NavigationComponent
    @open-demo-modal="openDemoModal"
    @open-login-modal="openLoginModal"
  />
  <router-view />
  <DemoComponent :isOpen="isDemoModalOpen" @close="closeDemoModal" />
  <LoginComponent
    :isOpen="isLoginModalOpen"
    @close="closeLoginModal"
    @open-recovery-modal="openRecoveryModal"
  />
  <RecoveryPasswordComponent
    :isOpen="isRecoveryModalOpen"
    @close="closeRecoveryModal"
  />
  <!-- <section
    id="contacts"
    class="footer-component"
    :style="{ backgroundImage: `url(${backgroundImageFooter})` }"
  >
    <FooterComponent @open-demo-modal="openDemoModal" /> 
  </section> -->
</template>

<script>
import NavigationComponent from "./components/NavigationComponent.vue";
import DemoComponent from "./components/DemoComponent.vue";
import LoginComponent from "./components/LoginComponent.vue";
import RecoveryPasswordComponent from "./components/RecoveryPasswordComponent.vue";
//import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    NavigationComponent,
    DemoComponent,
    LoginComponent,
    RecoveryPasswordComponent,
    //FooterComponent,
  },
  data() {
    return {
      isDemoModalOpen: false,
      isLoginModalOpen: false,
      isRecoveryModalOpen: false,
      backgroundImageFooter: require("@/assets/section-footer.svg"),
    };
  },
  methods: {
    openDemoModal() {
      this.isDemoModalOpen = true;
    },
    closeDemoModal() {
      this.isDemoModalOpen = false;
    },
    openLoginModal() {
      this.isLoginModalOpen = true;
    },
    closeLoginModal() {
      this.isLoginModalOpen = false;
    },
    openRecoveryModal() {
      this.isRecoveryModalOpen = true;
    },
    closeRecoveryModal() {
      this.isRecoveryModalOpen = false;
    },
  },
};
</script>
