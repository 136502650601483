<template>
  <div class="mission">
    <div class="container">
      <div class="mission__wrapper">
        <div class="mission__wrapper--left" data-aos="fade-right">
          <h2 class="mission__subheadline">
            Our
            <span class="mission__subheadline--color-change">mission</span> at
            AssetFloow is to revolutionize the Retail and CPG landscape by
            introducing the world's first Behavioral AI software.
          </h2>
          <p class="mission__text">
            We are dedicated to providing our clients with the tools to predict
            consumer behavior accurately, optimize operations seamlessly, drive
            increased sales, and eradicate out-of-stock scenarios and food
            waste. By doing so, we aim to propel our clients to the forefront of
            innovation and sustainability, fostering their growth and impact on
            a global scale.
          </p>
        </div>
        <div class="mission__wrapper--right" data-aos="fade-left">
          <img
            src="@/assets/mission-image1.png"
            alt="mission"
            class="mission__image"
          />
          <img
            src="@/assets/mission-image2.png"
            alt="mission"
            class="mission__image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/styles.scss";

.mission {
  &__headline {
    font-family: "aeonikmedium";
    font-size: 42px;

    @include responsive($breakpoint) {
      margin-bottom: 80px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include responsive($breakpoint-wider) {
      flex-direction: row;
    }

    &--left,
    &--right {
      display: flex;
      flex-direction: column;

      @include responsive($breakpoint-wider) {
        width: 50%;
        padding: 0;
      }
    }

    &--right {
      flex-direction: row;
      margin-top: 80px;

      @include responsive($breakpoint) {
        justify-content: center;
      }

      @include responsive($breakpoint-wider) {
        margin: 0;
        padding: 4rem;
        justify-content: inherit;
      }
    }
  }

  &__subheadline {
    font-size: 40px;
    font-family: "aeonik";
    margin-bottom: 2rem;
    text-align: left;

    &--color-change {
      @extend %colorchange;
    }
  }

  &__text {
    font-size: 24px;
    width: 100%;
    text-align: left;

    @include responsive($breakpoint) {

    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    width: 46%;

    @include responsive($breakpoint) {
      width: 30%;
    }

    @include responsive($breakpoint-wider) {
      width: 46%;
    }

    &:first-of-type {
      margin-right: 2rem;
      padding-top: 3rem;
    }

    &:last-of-type {
      padding-bottom: 3rem;
    }
  }
}
</style>
