<template>
  <div class="policy container">
    <h1 class="policy__headline">Privacy Policy</h1>
    <h4 class="policy__update">Last updated March 2023</h4>
    <h5 class="policy__subheadline">General</h5>
    <p class="policy__text">These terms and conditions outline the rules and regulations for the use of AssetFloow
      Analytic’s Website.
      AssetFloow is located at:
      Av. João Paulo II, 132, Lisbon - Portugal.
      By accessing this website we assume you accept these terms and conditions in full. Do not continue to use AssetFloow
      website if you do not accept all of the terms and conditions stated on this page.
      The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or
      all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the
      Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
      “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to
      the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the
      Client in the most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the
      express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services/products,
      in accordance with and subject to, prevailing law of Norway. Any use of the above terminology or other words in the
      singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to
      same.</p>
    <h5 class="policy__subheadline">Cookies</h5>
    <p class="policy__text">We employ the use of cookies. By using AssetFloow’s website you consent to the use of cookies
      in accordance with AssetFloow privacy policy.
      Most of the modern-day interactive web sites use cookies to enable us to retrieve user details for each visit.
      Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people
      visiting. Some of our affiliate/advertising partners may also use cookies.</p>
    <h5 class="policy__subheadline">License</h5>
    <p class="policy__text">Unless otherwise stated, AssetFloow and/or it’s licensors own the intellectual property rights
      for all material on AssetFloow. All intellectual property rights are reserved. You may view and/or print pages from
      <a class="policy__link" href="http://www.AssetFloow.com/">http://www.AssetFloow.com/</a> for your own personal use
      subject to
      restrictions set in these terms and conditions.
      You must not:
    <ol class="policy__numbered">
      <li>Republish material from <a class="policy__link" href="http://www.AssetFloow.com/">http://www.AssetFloow.com/</a>
      </li>
      <li>Sell, rent, or sub-license material from <a class="policy__link"
          href="http://www.AssetFloow.com/">http://www.AssetFloow.com/</a></li>
      <li>Reproduce, duplicate or copy material from <a class="policy__link"
          href="http://www.AssetFloow.com/">http://www.AssetFloow.com/</a>
        Redistribute content from AssetFloow (unless content is specifically made for redistribution).</li>
    </ol>
    </p>
    <h5 class="policy__subheadline">Hyperlinking to our Content</h5>
    <ol class="policy__numbered">
      <li>The following organizations may link to our Web site without prior written approval:
        <ul class="policy__lettered">
          <li>a. Government agencies;</li>
          <li>b. Search engines;</li>
          <li>c. News organizations;</li>
          <li>d. Online directory distributors when they list us in the directory may link to our Web site in the same
            manner as they
            hyperlink to the Web sites of other listed businesses; and</li>
          <li>e. Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and
            charity
            fundraising groups that may not hyperlink to our Web site.</li>
        </ul>
      </li>
      <li>These organizations may link to our home page, to publications or to other Web site information so long as the
        link:
        (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking
        party and its products or services; and (c) fits within the context of the linking party’s site.</li>
      <li>We may consider and approve in our sole discretion other link requests from the following types of
        organizations:.
        <ul class="policy__lettered">
          <li>a. commonly-known consumer and/or business information sources such as Chambers of Commerce, American
            Automobile
            Association, AARP and Consumers Union;</li>
          <li>b. dot.com community sites;</li>
          <li>c. associations or other groups representing charities, including charity giving sites,</li>
          <li>d. online directory distributors;</li>
          <li>e. internet portals;</li>
          <li>f. iaccounting, law and consulting firms whose primary clients are businesses; and</li>
          <li>g. educational institutions and trade associations.</li>
        </ul>
      </li>
    </ol>
    <p class="policy__text">
      We will approve link requests from these organizations if we determine that: (a) the link would not reflect
      unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing
      inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b)the
      organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated
      with the hyperlink outweighs the absence of ; and (d) where the link is in the context of general resource
      information or is otherwise consistent with editorial content in a newsletter or similar product furthering the
      mission of the organization.
    </p>
    <p class="policy__text">
      These organizations may link to our home page, to publications or to other Web site information so long as the link:
      (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking
      party and it products or services; and (c) fits within the context of the linking party’s site.
    </p>
    <p class="policy__text">
      If you are among the organizations listed in paragraph 2 above and are interested in linking to our website, you
      must notify us by sending an e-mail to <a class="policy__link"
        href="mailto:hello@assetfloow.com">hello@assetfloow.com</a>.Please include your name, your organization name,
      contact
      information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from
      which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link.
      Allow 2-3 weeks for a response.
      Approved organizations may hyperlink to our Web site as follows:
    </p>
    <ol class="policy__numbered">
      <li>By use of our corporate name; or</li>
      <li>By use of the uniform resource locator (Web address) being linked to; or</li>
      <li>By use of any other description of our Web site or material being linked to that makes sense within the context
        and
        format of content on the linking party’s site.</li>
    </ol>
    <p class="policy__text">No use of AssetFloow logo or other artwork will be allowed for linking absent a trademark
      license agreement.</p>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.policy {
  &__headline {
    font-size: 50px;
    line-height: 60px;
    padding-top: 40px;
    margin-bottom: 20px;
  }

  &__update {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 80px;
  }

  &__subheadline {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;

    &:not(:first-of-type) {
      margin-top: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, #FF8C00 0%, #0067FF 100%);
    }
  }

  &__text {
    font-size: 14px;
    line-height: 17px;
  }

  &__link {
    font-family: 'aeonik';
    text-decoration: none;
    color: #00204D;
  }

  &__numbered {
    font-size: 14px;
    line-height: 17px;
    padding-left: 2rem;
  }

  &__lettered {
    list-style: none;
    padding-left: 1rem;
  }
}
</style>