<template>
  <div class="slider">
    <div
      class="slider__wrapper"
      @touchstart="touchStart"
      @touchmove="touchMove"
      @touchend="touchEnd"
    >
      <div v-for="slide in visibleSlides" :key="slide.id" class="slider__slide">
        <div class="slider__card slider-card">
          <img
            :src="slide.image"
            alt="Slide Image"
            class="slider-card__image"
          />
          <div class="slider-card__content">
            <h3 class="slider-card__title">{{ slide.title }}</h3>
            <p class="slider-card__description">{{ slide.description }}</p>
            <a :href="slide.link" target="_blank" class="slider-card__link">Read more</a>
          </div>
        </div>
      </div>
    </div>
    <div class="slider__buttons">
      <button class="slider__button" @click="prevSlide">←</button>
      <button class="slider__button" @click="nextSlide">→</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      startX: 0,
      endX: 0,
      slides: [
        {
          id: 1,
          image: require("@/assets/media1.png"),
          title: "Using AI: Female-led retail tech startup captures €1.5M.",
          description:
            "By changing the way they research and buy products, consumers.",
          link: "https://techfundingnews.com/using-ai-female-led-retail-tech-startup-captures-e1-5m-to-describe-consumer-behaviour-inside-a-store-without-cameras/",
        },
        {
          id: 2,
          image: require("@/assets/media2.png"),
          title: "AssetFloow awarded the Most Promising Startup in Portugal.",
          description:
            "A AssetFloow foi eleita como Startup Mais Promissora da Startup Lisboa ao ser a vencedora do Demo Day.",
          link: "https://www.startuplisboa.com/startuplisboanews/miguel-santo-amaro-rui-carvalho-tiago-fernandes-lus-rocha-e-nuno-pinto-fundadores-da-coverflex-vencem-prmio-joo-vasconcelos-empreendedor-do-ano-2022",
        },
        {
          id: 3,
          image: require("@/assets/media3.png"),
          title: "Andrés Osto takes on Senior Advisor role at AssetFloow.",
          description:
            "La startup portuguesa AssetFloow, especializada en inteligencia artificial",
          link: "https://www.alimarket.es/alimentacion/noticia/330529/andres-osto-se-incorpora-a-assetfloow-como-senior-advisor",
        },
      ],
    };
  },
  computed: {
    visibleSlides() {
      return [
        this.slides[
          (this.currentIndex - 1 + this.slides.length) % this.slides.length
        ],
        this.slides[this.currentIndex],
        this.slides[(this.currentIndex + 1) % this.slides.length],
      ];
    },
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },
    touchStart(e) {
      this.startX = e.touches[0].clientX;
    },
    touchMove(e) {
      this.endX = e.touches[0].clientX;
    },
    touchEnd() {
      if (this.startX < this.endX) {
        this.prevSlide();
      } else if (this.startX > this.endX) {
        this.nextSlide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";
.slider-card {
  &__image {
    width: 100%;
    height: 33vh;
    object-fit: cover;
    display: block;
  }

  &__content {
    padding: 1rem;
    text-align: left;
  }

  &__title{
    font-size: 22px;
    color: #1745A3;
    margin-bottom: 2rem;
    min-height: 75px;
  }

  &__description {
    font-size: 18px;
    color: #ABB3BF;
    margin-bottom: 2rem;
    min-height: 80px;
  }

  &__link {
    display: inline-block;
    color: #007bff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      color: #0056b3;
    }
  }
}

.slider {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    padding: 2rem 0;
  }

  &__card {
    width: 100%;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 2px 10px 20px 5px rgba(23, 69, 163, 0.05);

    @include responsive($breakpoint) {
      width: 90%;
    }
  }

  &__slide {
    min-width: 100%;

    @include responsive($breakpoint) {
      flex: 1;
      min-width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 0.3s ease;
    }

    @include responsive($breakpoint-wider) {
      min-width: 33.33%;
    }
  }

  &__buttons {
    margin-top: 2rem;
  }

  &__button {
    color: #1e1e1e;
    background: #f0f2f5;
    box-shadow: none;
    border: none;
    margin-right: 1rem;
    padding: 10px;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      opacity: .9;
    }

    &:last-child {
      color: white;
      background: #0067FF;
      margin: 0;
    }
  }
}
</style>
