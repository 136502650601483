<template>
  <div class="table-wrapper">
    <div class="table">
      <div class="table__row">
        <div class="table__cell table__cell--bold"></div>
        <div class="table__cell">
          <img
            class="table__logos table__logos--yandex"
            src="@/assets/logos/yandex.svg"
            alt="logo yandex"
          />
        </div>
        <div class="table__cell">
          <img
            class="table__logos"
            src="@/assets/logos/google.svg"
            alt="logo google"
          />
        </div>
        <div class="table__cell">
          <img
            class="table__logos"
            src="@/assets/logos/amazon.svg"
            alt="logo amazon"
          />
        </div>
        <div class="table__cell">
          <img
            class="table__logos"
            src="@/assets/logos/meta.svg"
            alt="logo meta"
          />
        </div>
        <div class="table__cell table__cell--span">
          <img
            class="table__image"
            src="@/assets/logos/assetfloow.svg"
            alt="logo assetfloow"
          />
        </div>
      </div>
      <div class="table__row">
        <div class="table__cell table__cell--bold"></div>
        <div class="table__cell">CatBoost</div>
        <div class="table__cell">WaveWest</div>
        <div class="table__cell">DeepAr</div>
        <div class="table__cell">Prophet</div>
        <div class="table__cell table__cell--gradient">
          DFN-1<br /><span class="table__cell--font-smaller">(2022)</span>
        </div>
        <div class="table__cell table__cell--gradient">
          DFN-2<br /><span class="table__cell--font-smaller">(2023)</span>
        </div>
        <div class="table__cell table__cell--gradient">
          DFN-3<br /><span class="table__cell--font-smaller"
            >(coming soon)</span
          >
        </div>
      </div>
      <div class="table__row">
        <div class="table__cell table__cell--bold">Traffic (mobility)</div>
        <div class="table__cell">1.116</div>
        <div class="table__cell">1.233</div>
        <div class="table__cell">1.182</div>
        <div class="table__cell">1.084</div>
        <div class="table__cell table__cell--color">0.951</div>
        <div class="table__cell table__cell--color">0.904</div>
        <div class="table__cell">0.500</div>
      </div>
      <div class="table__row">
        <div class="table__cell table__cell--bold">Hospital (health)</div>
        <div class="table__cell">0.798</div>
        <div class="table__cell">0.779</div>
        <div class="table__cell">0.769</div>
        <div class="table__cell">0.673</div>
        <div class="table__cell table__cell--color">0.951</div>
        <div class="table__cell table__cell--color">0.673</div>
        <div class="table__cell">0.400</div>
      </div>
      <div class="table__row">
        <div class="table__cell table__cell--bold">Electricity (energy)</div>
        <div class="table__cell">0.815</div>
        <div class="table__cell">1.250</div>
        <div class="table__cell">1.005</div>
        <div class="table__cell">0.924</div>
        <div class="table__cell table__cell--color">0.813</div>
        <div class="table__cell table__cell--color">0.713</div>
        <div class="table__cell">0.500</div>
      </div>
      <div class="table__row">
        <div class="table__cell table__cell--bold">NN5 (banking)</div>
        <div class="table__cell">0.853</div>
        <div class="table__cell">1.123</div>
        <div class="table__cell">0.863</div>
        <div class="table__cell">0.927</div>
        <div class="table__cell table__cell--color">1.143</div>
        <div class="table__cell table__cell--color">0.800</div>
        <div class="table__cell">0.600</div>
      </div>
      <div class="table__row">
        <div class="table__cell table__cell--bold">Car Parts (retail)</div>
        <div class="table__cell">0.853</div>
        <div class="table__cell">0.754</div>
        <div class="table__cell">0.747</div>
        <div class="table__cell">0.876</div>
        <div class="table__cell table__cell--color">0.927</div>
        <div class="table__cell table__cell--color">0.698</div>
        <div class="table__cell">0.400</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResearchTableComponent",
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";
.table {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1rem;
  overflow: scroll;

  &__row {
    display: contents;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: #abb3bf;
    height: 40px;

    &--bold {
      align-items: flex-start;
      font-family: "aeonikmedium";
      color: #07204a;
    }
    &--gradient {
      @extend %colorchange;

      font-family: "aeonikmedium";
      min-width: 73px;
      text-align: center;
    }
    &--color {
      font-family: "aeonikmedium";
      color: #1745a3;
    }

    &--span {
      grid-column: span 3;
    }

    &--font-smaller {
      font-size: 11px;
    }
  }

  &__image {
    max-width: 215px;
    padding: 0 2rem;
  }
}
</style>
