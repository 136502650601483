<template>
  <div class="values">
    <div class="values__wrapper">
      <div class="container">
        <div class="values__cards" data-aos="fade-up">
          <div class="values__card">
            <div class="values__card-wrapper">
              <img
                src="@/assets/values/icon-innovation.svg"
                class="values__image"
                alt="icon innovation"
              />
              <h6 class="values__title">Innovation</h6>
            </div>
            <p class="values__text">
              We are pioneers in the application of Behavioral AI. We foster a
              culture that thrives on continuous innovation, pushing the
              boundaries of technology to provide our clients with cutting-edge
              solutions that keep them ahead of the curve.
            </p>
          </div>
          <div class="values__card">
            <div class="values__card-wrapper">
              <img
                src="@/assets/values/icon-sustainability.svg"
                class="values__image"
                alt="icon sustainability"
              />
              <h6 class="values__title">Sustainability</h6>
            </div>
            <p class="values__text">
              AssetFloow is dedicated to helping our clients minimize their
              environmental footprint by eliminating out-of-stock situations and
              reducing food waste, contributing to a more sustainable and
              responsible future.
            </p>
          </div>
          <div class="values__card">
            <div class="values__card-wrapper">
              <img
                src="@/assets/values/icon-customer.svg"
                class="values__image"
                alt="icon customer"
              />
              <h6 class="values__title">Customer-Centricity</h6>
            </div>
            <p class="values__text">
              We prioritize understanding and meeting our customers' unique
              needs, striving to offer solutions that add value, improve
              efficiency, and contribute to our clients' success.
            </p>
          </div>
          <div class="values__card">
            <div class="values__card-wrapper">
              <img
                src="@/assets/values/icon-ethic.svg"
                class="values__image"
                alt="icon ethic"
              />
              <h6 class="values__title">Ethical Use of Data</h6>
            </div>
            <p class="values__text">
              We prioritize the responsible and ethical use of data, ensuring
              privacy and confidentiality while extracting valuable insights.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/styles.scss";

.values {
  &__headline {
    font-family: "aeonikmedium";
    font-size: 42px;
    margin-bottom: 80px;

    @include responsive($breakpoint) {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    background: var(
      --Social-Network,
      linear-gradient(
        213deg,
        #f093375a -8.02%,
        #f08f9e65 49.21%,
        #2769f661 104.1%
      )
    );
    padding: 6rem 0;

    @include responsive($breakpoint) {
      padding: 8rem 0;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include responsive($breakpoint) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 3rem;
    }
  }

  &__card {
    border-radius: 8px;
    padding: 3rem;
    background: white;
    box-shadow: 1.58205px 7.91026px 15.82052px 3.95513px rgba(23, 69, 163, 0.05);
    margin-bottom: 2rem;

    @include responsive($breakpoint) {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__card-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__image {
    height: 35px;
    width: auto;
    padding-right: 1rem;
  }

  &__title {
    font-family: "aeonikmedium";
    font-size: 25px;
    text-align: left;
  }

  &__text {
    font-size: 24px;
    margin-bottom: 2rem;
    text-align: left;
    align-self: flex-end;

    @include responsive($breakpoint) {
      max-width: 450px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
