<template>
  <div class="research container">
    <div class="research__wrapper">
      <h1 class="research__headline" data-aos="zoom-in">
        <span class="research--color-change">AssetFloow</span> is leading the
        global Research in AI Forecasting
      </h1>
      <h1 class="research__headline" data-aos="fade-up">
        New architecture:
        <span class="research--color-change">Dynamic Flow Network</span>
      </h1>
      <div class="research__boxes" data-aos="fade-up">
        <div class="research__box">
          <img
            class="research__box-image"
            src="@/assets/research/nodes.svg"
            alt="node"
            style="width: 135px"
          />
          <h2 class="research__box-title">Node and Event</h2>
          <p class="research__box-text">
            A Node is the fundamental unit within the system, representing and
            individual element. It is a data point that changes over time and
            may interact within the system.
          </p>
        </div>
        <div class="research__box">
          <img
            class="research__box-image"
            src="@/assets/research/cluster.svg"
            alt="node"
            style="width: 135px"
          />
          <h2 class="research__box-title">Cluster</h2>
          <p class="research__box-text">
            A Cluster is a collection of Nodes that function together. Nodes
            within a Cluster collaborate to achieve a common objective,
            enhancing the overall efficiency and capabilities of the system.
          </p>
        </div>
        <div class="research__box">
          <img
            class="research__box-image"
            src="@/assets/research/network.svg"
            alt="node"
            style="width: 275px"
          />
          <h2 class="research__box-title">Network</h2>
          <p class="research__box-text">
            A Network is a higher-level structure that encompasses multiple
            Clusters. It defines the interconnections and relationships between
            Clusters, facilitating communication and coordination on a broader
            scale. Networks enable the collaboration of Clusters to achieve
            complex, distributed, or interconnected tasks within the overall
            system architecture.
          </p>
        </div>
      </div>
      <h1 class="research__headline" data-aos="fade-up">
        The World’s most accurate
        <span class="research--color-change">AI Forecast</span>*
      </h1>

      <ResearchTableComponent class="research__table" data-aos="fade-up" />
      <h6 class="research__note">
        *Results according to
        <a
          class="research__note-link"
          href="http://forecastingdata.org/"
          target="_blank"
          >http://forecastingdata.org/</a
        >
        using Mean Absolute Scaled Error (MASE) as error calculation.
      </h6>
      <!-- <p class="research__box-text">
        Subscribe to our newsletter and receive notifications about new research
      </p> -->
      <!-- <button class="research__button">Subscribe</button> -->
      <h1 class="research__headline" data-aos="fade-up">
        What does this improvement in relation to the
        <span class="research--color-change">state-of-the-art</span> mean?
      </h1>
      <div class="research__percentages" data-aos="fade-up">
        <div class="research__percentage">
          <div class="research__percentage-container">
            <div class="research__percentage-arrow">
              <img
                class="research__percentage-icon"
                src="@/assets/research/arrow-up.svg"
                alt="arrow up"
              />
            </div>
            <div class="research__percentage-wrapper">
              <div ref="counter1" class="research__percentage-symbol counter-1">
                28%
              </div>
              <p class="research__percentage-text">
                Increased accuracy in models
              </p>
            </div>
          </div>
          <h3 class="research__percentage-caption">Explainable results</h3>
        </div>
        <div class="research__percentage">
          <div class="research__percentage-container">
            <div class="research__percentage-arrow">
              <img
                class="research__percentage-icon"
                src="@/assets/research/arrow-down.svg"
                alt="arrow down"
              />
            </div>
            <div class="research__percentage-wrapper">
              <div ref="counter2" class="research__percentage-symbol counter-2">
                86%
              </div>
              <p class="research__percentage-text">
                Cost reduction in cloud processing
              </p>
            </div>
          </div>
          <h3 class="research__percentage-caption">No parameters input</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="research__media">
    <div class="container">
      <h1 class="research__headline" data-aos="fade-up">
        <strong>Research papers</strong>
      </h1>
      <h2 class="research__subheadline" data-aos="fade-up">
        Learn more about the
        <span class="research--color-change">science</span> behind our models.
      </h2>
      <!-- <a href="#" target="_blank" class="research__link"
          >Sales and Food waste impact calculator<span class="research__date"
            >October 30, 2023</span
          ></a
        >
        <a href="#" target="_blank" class="research__link"
          >Responsible consumption and Production<span class="research__date"
            >October 20, 2023</span
          ></a -->
      <!-- > -->
      <a
        href="https://www.linkedin.com/pulse/dynamic-flow-network-general-ai-forecast-model-assetfloow-enmmf%3FtrackingId=YPGnuhVynXPMKb7IjZblEw%253D%253D/?trackingId=YPGnuhVynXPMKb7IjZblEw%3D%3D"
        target="_blank"
        class="research__link"
        data-aos="fade-up"
      >
        Outperforming WaveNet, DeepAR, CatBoost and Prophet
        <span class="research__date">February 02, 2024</span>
      </a>
      <a
        href="https://www.linkedin.com/pulse/behavioural-ai-forecast-99-accuracy-zero-stockout-food-waste%3FtrackingId=RKR24w8mDr2wlMmnubGPpQ%253D%253D/?trackingId=RKR24w8mDr2wlMmnubGPpQ%3D%3D"
        target="_blank"
        class="research__link"
        >Behavioural AI Forecast: +99% accuracy for zero stockout, zero food
        waste<span class="research__date">October 10, 2023</span></a
      >
    </div>
  </div>
</template>

<script>
import PureCounter from "@srexi/purecounterjs";
import ResearchTableComponent from "@/components/ResearchTableComponent.vue";

export default {
  name: "ResearchPage",
  components: { ResearchTableComponent },
  mounted() {
    this.initializeCounters();
    window.scrollTo(0, 0);
  },
  methods: {
    initializeCounters() {
      this.startCounter(".counter-1", 0, 28);
      this.startCounter(".counter-2", 0, 86);
    },
    startCounter(selector, start, end) {
      this.$nextTick(() => {
        new PureCounter({
          selector,
          start,
          end,
          duration: 2,
          delay: 10,
          once: true,
          repeat: false,
          decimals: 0,
          legacy: true,
          filesizing: false,
          currency: false,
          separator: false,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";
.research {
  margin-top: 5rem;
  text-align: center;

  &--color-change {
    @extend %colorchange;
  }

  &__wrapper {
    max-width: 900px;
    margin: 0 auto;
  }

  &__headline {
    font-family: "aeoniklight";
    font-weight: unset;
    font-size: 50px;
    margin: 0;

    @include responsive($breakpoint-wider) {
      margin-bottom: 80px;
    }

    &:first-of-type {
      background-image: url("@/assets/research/logo-assetfloow-animated.gif");
      background-size: contain;
      padding: 4rem 0;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 80px;

      @include responsive($breakpoint-wider) {
        padding: 10rem 0;
        margin: 0;
        margin-bottom: 120px;
      }
    }

    &:last-of-type {
      padding: 0;
      background: none;
      margin-bottom: 1rem;
    }
  }

  &__subheadline {
    font-size: 40px;
    margin-bottom: 200px;
  }

  &__boxes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include responsive($breakpoint-wider) {
      flex-direction: row;
      margin-bottom: 120px;
    }
  }

  &__box {
    width: 100%;
    margin-bottom: 3rem;

    &:last-of-type {
      margin-bottom: 100px;
    }

    @include responsive($breakpoint-wider) {
      width: 30%;
      margin: 0;
    }
  }

  &__box-image {
    height: 215px;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &__box-title {
    font-size: 32px;
    margin: 2rem 0;

    @include responsive($breakpoint-wider) {
      margin: 0 0 1rem 0;
    }
  }

  &__box-text {
    font-size: 20px;
    text-align: left;
  }

  &__text {
    width: 70%;
    margin: 0 auto 3rem auto;
    font-size: 24px;
  }

  // &__button {
  //   border: none;
  //   border-radius: 12.5rem;
  //   background: #ff899c;
  //   color: white;
  //   font-size: 16px;
  //   padding: 0.6rem 4rem;
  //   box-shadow: 2px 10px 20px 5px rgba(23, 69, 163, 0.05);
  // }

  &__table {
    margin: 80px 0 3rem 0;
  }

  &__note {
    font-size: 16px;
    margin-bottom: 100px;
  }

  &__note-link {
    text-decoration: none;
    color: #00204d;
    border-bottom: 1px solid;
  }

  &__percentages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;

    @include responsive($breakpoint) {
      flex-direction: row;
    }
  }

  &__percentage {
    display: flex;
    flex-wrap: wrap;
    text-align: left;

    @include responsive($breakpoint-wider) {
      width: 45%;
    }

    &:first-of-type {
      margin-bottom: 100px;

      @include responsive($breakpoint-wider) {
        margin: 0;
        justify-content: flex-end;
      }
    }
  }

  &__percentage-icon {
    width: 130px;

    @include responsive($breakpoint-wider) {
      width: 105px;
    }
  }

  &__percentage-container {
    display: flex;
  }

  &__percentage-wrapper {
    margin-left: 1rem;
  }

  &__percentage-symbol {
    @extend %colorchange;

    font-size: 96px;
    position: relative;

    &:after {
      @extend %colorchange;

      content: "%";
      position: absolute;
      font-size: 96px;
      top: 0;
    }
  }

  &__percentage-text {
    font-size: 24px;

    @include responsive($breakpoint-wider) {
      font-size: 28px;
      max-width: 245px;
    }
  }

  &__percentage-caption {
    @extend %colorchange;

    font-size: 31px;
    margin-top: 1rem;

    @include responsive($breakpoint-wider) {
      font-size: 40px;
    }
  }

  &__media {
    background-image: url("@/assets/research/research-background.svg");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    margin-top: 120px;
    padding-top: 120px;
    text-align: center;
  }

  &__link {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 2rem 0;
    border-top: 1px solid #abb3bf;
    text-decoration: none;
    color: #07204a;
    font-size: 18px;

    &--disabled {
      color: #abb3bf;
      pointer-events: none !important;
    }

    @include responsive($breakpoint) {
      flex-direction: row;
      justify-content: space-between;
      font-size: 24px;
    }

    &:last-child {
      border-bottom: 1px solid #abb3bf;
    }
  }

  &__date {
    margin-top: 1rem;

    @include responsive($breakpoint) {
      margin: 0;
    }
  }

  &--color-change {
    @extend %colorchange;
  }
}
</style>
