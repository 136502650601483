<template>
  <div class="about">
    <div class="about__background" data-aos="fade-up">
      <div class="container">
        <h1 class="about__headline">
          <strong>Behavioral AI software</strong> that drives profitable and
          sustainable growth for retail
        </h1>
      </div>
    </div>
    <section>
      <VisionComponent />
    </section>
    <section>
      <MissionComponent />
    </section>
    <section>
      <ValuesComponent />
    </section>
    <div class="container container--small">
      <h2
        class="about__subheadline about__subheadline--underscored"
        data-aos="fade-up"
      >
        Media Highlights
      </h2>
      <p class="about__text" data-aos="fade-up">
        Discover featured articles, awards, and noteworthy appearances that
        underscore our influence in the dynamic realms of AI and Retail.
      </p>
    </div>
    <div class="container">
      <SliderComponent
        class="about__slider"
        data-aos="fade-up"
      ></SliderComponent>
    </div>
    <div class="about__touch">
      <div class="container container--small">
        <div class="about__image-container">
          <img
            class="about__image"
            src="@/assets/icon-get-in-touch.png"
            alt="Get in touch"
          />
        </div>
        <h2 class="about__subheadline">Get in touch</h2>
        <p class="about__text">
          We will contact you as soon as possible to confirm your request.
        </p>
        <button @click="openDemoModal" class="about__button">
          Request demo
        </button>
      </div>
    </div>
    <DemoComponent
      class="about__demo"
      :isOpen="isDemoModalOpen"
      @close="closeDemoModal"
    />
  </div>
</template>

<script>
import DemoComponent from "@/components/DemoComponent.vue";
import VisionComponent from "@/components/VisionComponent.vue";
import MissionComponent from "@/components/MissionComponent.vue";
import ValuesComponent from "@/components/ValuesComponent.vue";
import SliderComponent from "@/components/SliderComponent.vue";

export default {
  name: "AboutPage",
  components: {
    DemoComponent,
    VisionComponent,
    MissionComponent,
    ValuesComponent,
    SliderComponent,
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      isDemoModalOpen: false,
    };
  },
  methods: {
    openDemoModal() {
      this.isDemoModalOpen = true;
    },
    closeDemoModal() {
      this.isDemoModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

//helper blocks
.about-box {
  &__content {
    text-align: left;
    padding-left: 3rem;
    max-width: 400px;
  }

  &__headline {
    font-family: "aeonik";
    font-size: 32px;
    padding-bottom: 0.5rem;
  }

  &__text {
    font-size: 18px;
  }
}
.about {
  text-align: center;
  margin-top: 80px;

  &__background {
    background-image: url("@/assets/background-about.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    padding: 4rem 0;

    @include responsive($breakpoint) {
      height: 500px;
    }
  }

  &__headline {
    font-size: 34px;
    color: white;
    text-align: left;
    width: 60%;

    @include responsive($breakpoint) {
      font-size: 54px;
      max-width: 400px;
    }
  }

  &__subheadline {
    display: inline-flex;
    font-size: 42px;
    margin-bottom: 2rem;

    &--inversed {
      border-color: white;
    }
  }

  &__text {
    padding: 0 2rem;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 2rem;

    &--color-change {
      color: #0067ff;
    }
  }

  &__image-container {
    margin: 2rem 0;
  }

  &__image {
    width: 100px;
  }

  &__button {
    @include button;

    background: $button-background-2;
    border: none;
    box-shadow: none;
    color: $white-color;

    margin: 0;
    padding: 1rem;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    border-top: 1px solid #0067ff;
    text-decoration: none;
    color: #07204a;

    &:last-child {
      border-bottom: 1px solid;
    }
  }

  &__banner {
    color: white;
    padding: 4rem 0;
    background-image: url("@/assets/banner-mission.png");
    background-size: cover;
    margin: 80px 0;

    &--invert {
      background: #f0f2f5;
      color: #1745a3;

      .about__subheadline--underscored {
        border-color: #1745a3;
      }

      .about__text {
        margin-bottom: 3rem;
      }
    }
  }

  &__boxes {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
    margin: 80px 0 120px 0;

    @include responsive($breakpoint) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__box {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: start;
  }

  &__slider {
    margin: 80px 0;
  }

  &__demo {
    margin: 0;
    text-align: left;
  }
}
</style>
