<template>
  <div class="vision">
    <div class="container">
      <div class="vision__wrapper">
        <div class="vision__wrapper--left" data-aos="fade-right">
          <img
            src="@/assets/vision-image1.png"
            alt="vision"
            class="vision__image"
          />
          <img
            src="@/assets/vision-image2.png"
            alt="vision"
            class="vision__image"
          />
        </div>
        <div class="vision__wrapper--right" data-aos="fade-left">
          <h2 class="mission__subheadline">
            At AssetFloow, we
            <span class="mission__subheadline--color-change">envision</span> a
            future where the Retail and CPG industries operate with
            unprecedented precision, efficiency and sustainability.
          </h2>
          <p class="mission__text">
            Our vision is to be the driving force behind a transformative era in
            retail, where businesses seamlessly predict and respond to consumer
            behavior, optimizing operations, maximizing sales, and eliminating
            the detrimental effects of out-of-stock scenarios and food waste.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../styles/styles.scss";

.vision {
  &__headline {
    font-family: "aeonikmedium";
    font-size: 42px;

    @include responsive($breakpoint) {
      margin-bottom: 80px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include responsive($breakpoint-wider) {
      flex-direction: row;
    }

    &--left,
    &--right {
      display: flex;
      
      @include responsive($breakpoint-wider) {
        width: 50%;
      }
    }

    &--left {
      margin-top: 80px;
      order: 2;

      @include responsive($breakpoint) {
        justify-content: center;
      }
      
      @include responsive($breakpoint-wider) {
        margin: 0;
        padding: 4rem;
        order: 1;
        justify-content: initial;
      }
    }
    
    &--right {
      order: 1;
      flex-direction: column;
      
      @include responsive($breakpoint-wider) {
        order: 2;
      }
    }
  }

  &__subheadline {
    font-size: 40px;
    font-family: "aeonik";
    margin-bottom: 2rem;
    text-align: left;

    &--color-change {
      @extend %colorchange;
    }
  }

  &__text {
    font-size: 24px;
    margin-bottom: 2rem;
    width: 100%;
    text-align: left;

    @include responsive($breakpoint) {
      max-width: 450px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    width: 46%;

    @include responsive($breakpoint) {
      width: 30%;
    }

    @include responsive($breakpoint-wider) {
      width: 46%;
    }

    &:first-of-type {
      margin-right: 2rem;
      padding-bottom: 3rem;
    }
    
    &:last-of-type {
      padding-top: 3rem;
    }
  }
}
</style>
