<template>
  <DemoComponent :isOpen="isDemoModalOpen" @close="closeDemoModal" />
  <section>
    <IntroductionComponent />
  </section>
  <!-- <section>
    <TabComponent />
  </section>
  <section>
    <PercentageComponent @open-demo-modal="openDemoModal" />
  </section>
  <section>
    <IndustriesComponent/>
  </section>
  <section>
    <TestimonialSliderComponent />
  </section>
  <section>
    <ContactComponent />
  </section> -->
</template>

<script>
import IntroductionComponent from "@/components/IntroductionComponent.vue";
import DemoComponent from "@/components/DemoComponent.vue";
/* import TabComponent from "@/components/TabComponent.vue";
import PercentageComponent from "@/components/PercentageComponent.vue";
import IndustriesComponent from "@/components/IndustriesComponent.vue";
import TestimonialSliderComponent from "@/components/TestimonialSliderComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue"; */

export default {
  name: "App",
  components: {
    IntroductionComponent,
    //TabComponent,
    //PercentageComponent,
    // IndustriesComponent,
    DemoComponent,
    //TestimonialSliderComponent,
    //ContactComponent,
  },
  data() {
    return {
      isDemoModalOpen: false,
      isMobile: window.innerWidth <= 768,
      backgroundImageTabs: require("@/assets/section-tabs.png"),
    };
  },
  beforeRouteEnter(to) {
    if (to.name) {
      const section = document.getElementById(to.name.toLowerCase());
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth",
        });
      }
    }
  },
  mounted() {
    this.scrollToSection();
    this.scrollToSection(this.$route.name.toLowerCase());
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth",
        });
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    openDemoModal() {
      this.isDemoModalOpen = true;
    },
    closeDemoModal() {
      this.isDemoModalOpen = false;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
